import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-scroll';
import { MENU } from './resources/constants/menu';
import i18n from './i18n';

const Header = ({ currentPage, scrollToTop, t, headerLanguagePickerRef }) => {
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    return (
        <div className={`header container flex align ${currentPage >= 1 && currentPage <= 4 ? 'dark' : ''}`}>
            <div className='container flex align'>
                <div className='navigation flex align'>
                    <div className='logo transition' onClick={scrollToTop}></div>
                    <div className='menu flex align'>
                        {MENU.filter(m => !m.hidden).map(m =>
                            <Link key={m.id} onClick={m.position === 0 ? scrollToTop : null} to={m.id} duration={500} smooth={true}>
                                <div className={`item highlighter transition ${currentPage === m.position ? 'selected' : ''}`}>
                                    <span className='text'>{t(m.label)}</span>
                                    <div className='highlight transition'></div>
                                </div>
                            </Link>
                        )}
                    </div>
                </div>
                <div className='buttons'>
                    <div className='lang-dropdown' ref={headerLanguagePickerRef}>
                        <div className='current'>
                            <div className={`icon transition ${i18n.language}`}></div>
                            <div className='arrow transition'></div>
                        </div>

                        <div className='picker'>
                            <ul className='rounded-s'>
                                <li className='transition' onClick={() => changeLanguage('fr')}>FR</li>
                                <li className='transition' onClick={() => changeLanguage('en')}>EN</li>
                            </ul>
                        </div>
                    </div>

                    <Link to={MENU[5].id} smooth={true} duration={500}>
                        <button className='default primary rounded-s transition'>
                            <span className='main-text transition'>{t("Work together")} <span className='emoji'>💪🏻</span></span>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default withNamespaces()(Header);