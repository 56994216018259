import React, { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import Slider from '../../Slider';
import { withNamespaces } from 'react-i18next';

const ItemWorkPanel = ({ work, showItems, position, t }) => {
    const descriptionRef = useRef();
    const [seeMoreAvailable, setSeeMoreAvailable] = useState(false);
    const [showMoreDescription, setShowMoreDescription] = useState(false);

    useEffect(() => {
        if (descriptionRef.current.scrollHeight > descriptionRef.current.offsetHeight)
            setSeeMoreAvailable(true);
    }, [descriptionRef]);

    return (
        <div className={`project opacity-fade-effect ${showItems ? 'visible' : ''}`} style={{ transitionDelay: `${position * 1}s` }}>
            <Slider backgroundColor={work.backgroundImage} images={work.images} />

            <div className='details'>
                <div className='title flex align'>
                    <h2>
                        <span className='text'>{work.title}</span> <span className='tiret highlighter selected'><span className='text'>-</span><div className='highlight transition'></div></span> <span className='text'>{work.year}</span>
                    </h2>
                </div>


                <div className={`description ${showMoreDescription ? 'extended' : ''}`} ref={descriptionRef}>
                    <p>{t(work.description)}</p>

                    {seeMoreAvailable &&
                        <div className='see-more'>
                            <div className='flex align'>
                                <div className='bar highlighter selected'><div className='highlight transition'></div></div>
                                <span onClick={() => setShowMoreDescription(!showMoreDescription)}>{showMoreDescription ? t("See less") : t("See more")}</span>
                                <div className='bar highlighter selected'><div className='highlight transition'></div></div>
                            </div>
                        </div>}
                </div>

                <h4>{t("What I did")}</h4>
                <div className='fields'>
                    {work.missions.map((m, index) => <span key={index} style={{ background: m.background }}>{t(m.label)}</span>)}
                </div>

                <h4>{t("Challenges")}</h4>
                <ul className='challenges'>
                    {work.challenges.map((c, index) => <li key={index}>{t(c)}</li>)}
                </ul>

                <h4>{t("Technologies")}</h4>
                <div className='tech'>
                    {work.techs.map((t, index) => <img key={index} src={t.logo} alt={t.name} />)}
                </div>

                {work.link &&
                    <a className='app-link highlighter selected' href={work.link} target={'_blank'} rel="noreferrer">
                        <span className='text'>{t("Visit website")}</span>
                        <div className='highlight transition'></div>
                    </a>}
            </div>
        </div>
    );
}

ItemWorkPanel.propTypes = {
    work: propTypes.object.isRequired
};

export default withNamespaces()(ItemWorkPanel);