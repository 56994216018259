import React, { useEffect, useRef, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import './css/main.scss';
import DotNavigation from './DotNavigation';
import Header from './Header';
import Home from './Home';
import CloudPanel from './panel/CloudPanel';
import ContactPanel from './panel/ContactPanel';
import MethodPanel from './panel/MethodPanel';
import SkillPanel from './panel/SkillPanel';
import WorkPanel from './panel/WorkPanel';

const App = () => {
    const [currentPage, setCurrentPage] = useState(0);

    const headerLanguagePickerRef = useRef();

    // Home
    const homeBackgroundRef = useRef();
    const homeHighlight1Ref = useRef();

    // Work
    const workPanelRef = useRef();
    const workOverlay1Ref = useRef();
    const workOverlay2Ref = useRef();
    const workHighlight1Ref = useRef();
    const workHighlight2Ref = useRef();

    // Skill
    const skillPanelRef = useRef();
    const skillOverlay1Ref = useRef();
    const skillOverlay2Ref = useRef();
    const skillHighlight1Ref = useRef();
    const skillHighlight2Ref = useRef();

    // Method
    const methodPanelRef = useRef();
    const methodOverlay1Ref = useRef();
    const methodOverlay2Ref = useRef();
    const methodHighlight1Ref = useRef();
    const methodHighlight2Ref = useRef();
    const methodHighlight3Ref = useRef();

    // Cloud
    const cloudPanelRef = useRef();
    const cloudOverlay1Ref = useRef();
    const cloudHighlight1Ref = useRef();
    const cloudHighlight2Ref = useRef();

    // Contact
    const contactPanelRef = useRef();
    const contactOverlay1Ref = useRef();

    const onScrolling = () => {
        const workBlurValue = 1 - Math.min(Math.max((workPanelRef.current.getBoundingClientRect().top / window.innerHeight), 0), 1);
        const workOverlayWidthPercent = (100 - (workBlurValue * 100));
        const workHighlightWidthPercent = workBlurValue * 96;
        workOverlay1Ref.current.style.width = `${workOverlayWidthPercent}%`;
        workOverlay2Ref.current.style.width = `${workOverlayWidthPercent * 2}%`;
        workHighlight1Ref.current.style.width = `${workHighlightWidthPercent}%`;
        workHighlight2Ref.current.style.width = `${workHighlightWidthPercent}%`;

        headerLanguagePickerRef.current.style.display = workBlurValue >= 1 ? 'none' : 'block';
        headerLanguagePickerRef.current.style.opacity = 1 - workBlurValue;

        homeBackgroundRef.current.style.display = workBlurValue >= 0.1 ? 'block' : 'none';
        homeBackgroundRef.current.style.opacity = workBlurValue;
        homeHighlight1Ref.current.style.width = `${Math.min(Math.max(((1 - workBlurValue) * 100), 0), 98)}%`;

        const skillBlurValue = 1 - Math.min(Math.max((skillPanelRef.current.getBoundingClientRect().top / window.innerHeight), 0), 1);
        const skillOverlayWidthPercent = (100 - (skillBlurValue * 100));
        const skillHighlightWidthPercent = skillBlurValue * 96;
        skillOverlay1Ref.current.style.width = `${skillOverlayWidthPercent}%`;
        skillOverlay2Ref.current.style.width = `${skillOverlayWidthPercent * 2}%`;
        skillHighlight1Ref.current.style.width = `${skillHighlightWidthPercent}%`;
        skillHighlight2Ref.current.style.width = `${skillHighlightWidthPercent}%`;

        const methodBlurValue = 1 - Math.min(Math.max((methodPanelRef.current.getBoundingClientRect().top / window.innerHeight), 0), 1);
        const methodOverlayWidthPercent = (100 - (methodBlurValue * 100));
        const methodHighlightWidthPercent = methodBlurValue * 96;
        methodOverlay1Ref.current.style.width = `${methodOverlayWidthPercent}%`;
        methodOverlay2Ref.current.style.width = `${methodOverlayWidthPercent * 2}%`;
        methodHighlight1Ref.current.style.width = `${methodHighlightWidthPercent}%`;
        methodHighlight2Ref.current.style.width = `${methodHighlightWidthPercent}%`;
        methodHighlight3Ref.current.style.width = `${methodHighlightWidthPercent}%`;

        const cloudBlurValue = 1 - Math.min(Math.max((cloudPanelRef.current.getBoundingClientRect().top / window.innerHeight), 0), 1);
        const cloudOverlayWidthPercent = (100 - (cloudBlurValue * 100));
        const cloudHighlightWidthPercent = cloudBlurValue * 96;
        cloudOverlay1Ref.current.style.width = `${cloudOverlayWidthPercent}%`;
        cloudHighlight1Ref.current.style.width = `${cloudHighlightWidthPercent}%`;
        cloudHighlight2Ref.current.style.width = `${cloudHighlightWidthPercent}%`;

        const contactBlurValue = 1 - Math.min(Math.max((contactPanelRef.current.getBoundingClientRect().top / window.innerHeight), 0), 1);
        const contactOverlayWidthPercent = (100 - (contactBlurValue * 100));
        contactOverlay1Ref.current.style.width = `${contactOverlayWidthPercent}%`;

        let cp = 0;
        if (contactBlurValue + 0.1 >= 1) {
            cp = 5;
        } else if(cloudBlurValue + 0.1 >= 1) {
            cp = 4
        } else if (methodBlurValue + 0.1 >= 1) {
            cp = 3
        } else if (skillBlurValue + 0.1 >= 1) {
            cp = 2;
        } else if (workBlurValue + 0.1 >= 1) {
            cp = 1;
        }

        setCurrentPage(cp);
    }

    const scrollToTop = () => {
        scroll.scrollTo(0, {
            duration: 500,
            smooth: true
        });
    }

    useEffect(() => {
        document.addEventListener('scroll', onScrolling);

        return () => {
            document.removeEventListener('scroll', onScrolling);
        }
    }, []);

    console.log(currentPage);
    return (
        <div className='page-wrapper'>
            <Header currentPage={currentPage} scrollToTop={scrollToTop} headerLanguagePickerRef={headerLanguagePickerRef} />
            <DotNavigation currentPage={currentPage} scrollToTop={scrollToTop} />

            <Home homeBackgroundRef={homeBackgroundRef}
                homeHighlight1Ref={homeHighlight1Ref} />

            <div className='content-wrapper'>
                <WorkPanel blurValue={currentPage >= 1}
                    workPanelRef={workPanelRef}
                    workOverlay1Ref={workOverlay1Ref}
                    workOverlay2Ref={workOverlay2Ref}
                    workHighlight1Ref={workHighlight1Ref}
                    workHighlight2Ref={workHighlight2Ref} />

                <SkillPanel blurValue={currentPage >= 2}
                    skillPanelRef={skillPanelRef}
                    skillOverlay1Ref={skillOverlay1Ref}
                    skillOverlay2Ref={skillOverlay2Ref}
                    skillHighlight1Ref={skillHighlight1Ref}
                    skillHighlight2Ref={skillHighlight2Ref} />

                <MethodPanel blurValue={currentPage >= 3}
                    methodPanelRef={methodPanelRef}
                    methodOverlay1Ref={methodOverlay1Ref}
                    methodOverlay2Ref={methodOverlay2Ref}
                    methodHighlight1Ref={methodHighlight1Ref}
                    methodHighlight2Ref={methodHighlight2Ref}
                    methodHighlight3Ref={methodHighlight3Ref} />

                <CloudPanel blurValue={currentPage >= 4}
                    cloudPanelRef={cloudPanelRef}
                    cloudOverlay1Ref={cloudOverlay1Ref}
                    cloudHighlight1Ref={cloudHighlight1Ref}
                    cloudHighlight2Ref={cloudHighlight2Ref} />

                <ContactPanel blurValue={currentPage >= 5}
                    contactPanelRef={contactPanelRef}
                    contactOverlay1Ref={contactOverlay1Ref} />
            </div>
        </div >
    );
}

export default App;