import HTML from '../../resources/images/html.png';
import Sass from '../../resources/images/sass.png';
import JavaScript from '../../resources/images/javascript.png';
import React from '../../resources/images/react.png';
import Redux from '../../resources/images/redux.png';
import Dotnet from '../../resources/images/dotnet.png';
import Azure from '../../resources/images/azure.png';
import Git from '../../resources/images/git.png';
import SQL from '../../resources/images/sql.png';
import Swift from '../../resources/images/swift.png';
import PHP from '../../resources/images/php.png';
import Java from '../../resources/images/java.png';
import XD from '../../resources/images/xd.png';
import illustrator from '../../resources/images/illustrator.png';
import UXUI from '../../resources/images/uxui.png';
import phoneBE from '../../resources/images/phone-BE.png';
import phoneLU from '../../resources/images/phone-LU.png';
import email from '../../resources/images/email.png';
import stripe from '../../resources/images/stripe.png';

import faDashboardImage1 from '../../resources/images/projects/formationacademy/dashboard/image1.png';
import faDashboardImage2 from '../../resources/images/projects/formationacademy/dashboard/image2.png';
import faDashboardImage3 from '../../resources/images/projects/formationacademy/dashboard/image3.png';
import faDashboardImage4 from '../../resources/images/projects/formationacademy/dashboard/image4.png';
import faDashboardImage5 from '../../resources/images/projects/formationacademy/dashboard/image5.png';
import faDashboardImage6 from '../../resources/images/projects/formationacademy/dashboard/image6.png';
import faDashboardImage7 from '../../resources/images/projects/formationacademy/dashboard/image7.png';
import faDashboardImage8 from '../../resources/images/projects/formationacademy/dashboard/image8.png';
import faDashboardImage9 from '../../resources/images/projects/formationacademy/dashboard/image9.png';
import faDashboardImage10 from '../../resources/images/projects/formationacademy/dashboard/image10.png';
import faDashboardImage11 from '../../resources/images/projects/formationacademy/dashboard/image11.png';
import faDashboardImage12 from '../../resources/images/projects/formationacademy/dashboard/image12.png';
import faDashboardImage13 from '../../resources/images/projects/formationacademy/dashboard/image13.png';

import combeeProImage1 from '../../resources/images/projects/combee/pro/image1.png';
import combeeProImage2 from '../../resources/images/projects/combee/pro/image2.png';
import combeeProImage3 from '../../resources/images/projects/combee/pro/image3.png';
import combeeProImage4 from '../../resources/images/projects/combee/pro/image4.png';
import combeeProImage5 from '../../resources/images/projects/combee/pro/image5.png';
import combeeProImage6 from '../../resources/images/projects/combee/pro/image6.png';
import combeeProImage7 from '../../resources/images/projects/combee/pro/image7.png';
import combeeProImage8 from '../../resources/images/projects/combee/pro/image8.png';
import combeeProImage9 from '../../resources/images/projects/combee/pro/image9.png';

import combeeImage1 from '../../resources/images/projects/combee/image1.png';
import combeeImage2 from '../../resources/images/projects/combee/image2.png';
import combeeImage3 from '../../resources/images/projects/combee/image3.png';
import combeeImage4 from '../../resources/images/projects/combee/image4.png';
import combeeImage5 from '../../resources/images/projects/combee/image5.png';
import combeeImage6 from '../../resources/images/projects/combee/image6.png';
import combeeImage7 from '../../resources/images/projects/combee/image7.png';
import combeeImage8 from '../../resources/images/projects/combee/image8.png';
import combeeImage9 from '../../resources/images/projects/combee/image9.png';
import combeeImage10 from '../../resources/images/projects/combee/image10.png';
import combeeImage11 from '../../resources/images/projects/combee/image11.png';
import combeeImage12 from '../../resources/images/projects/combee/image12.png';
import combeeImage13 from '../../resources/images/projects/combee/image13.png';
import combeeImage14 from '../../resources/images/projects/combee/image14.png';
import combeeImage15 from '../../resources/images/projects/combee/image15.png';
import combeeImage16 from '../../resources/images/projects/combee/image16.png';
import combeeImage17 from '../../resources/images/projects/combee/image17.png';
import combeeImage18 from '../../resources/images/projects/combee/image18.png';
import combeeImage19 from '../../resources/images/projects/combee/image19.png';
import combeeImage20 from '../../resources/images/projects/combee/image20.png';
import combeeImage21 from '../../resources/images/projects/combee/image21.png';
import combeeImage22 from '../../resources/images/projects/combee/image22.png';
import combeeImage23 from '../../resources/images/projects/combee/image23.png';

export const WORK_MISSION_TYPE = {
    front: {
        label: 'Frontend',
        background: '#18dcff'
    },
    back: {
        label: 'Backend',
        background: '#7d5fff'
    },
    uxui: {
        label: 'UI/UX',
        background: '#67e6dc'
    },
    business: {
        label: 'Business Development',
        background: '#3ae374'
    },
    projectManagement: {
        label: 'Project management',
        background: '#1b9cfc'
    },
    mobile: {
        label: 'Mobile development',
        background: '#f78fb3'
    }
}

export const PROGRAMMING_SKILLS = [
    {
        name: 'HTML',
        level: 'high',
        logo: HTML
    },
    {
        name: 'SCSS',
        level: 'high',
        logo: Sass
    },
    {
        name: 'JavaScript',
        level: 'high',
        logo: JavaScript
    },
    {
        name: 'React',
        level: 'high',
        logo: React
    },
    {
        name: 'Redux',
        level: 'high',
        logo: Redux
    },
    {
        name: '.NET',
        level: 'high',
        logo: Dotnet
    },
    {
        name: 'SQL',
        level: 'high',
        logo: SQL
    },
    {
        name: 'Azure',
        level: 'high',
        logo: Azure
    },
    {
        name: 'Git',
        level: 'medium',
        logo: Git
    },
    {
        name: 'Java',
        level: 'medium',
        logo: Java
    },
    {
        name: 'PHP',
        level: 'medium',
        logo: PHP
    },
    {
        name: 'Swift',
        level: 'low',
        logo: Swift
    }
];

export const DESIGN_SKILLS = [
    {
        name: 'UX | UI',
        level: 'high',
        logo: UXUI
    },
    {
        name: 'Adobe XD',
        level: 'high',
        logo: XD
    },
    {
        name: 'Illustrator',
        level: 'medium',
        logo: illustrator
    }
];

export const WORKS = [
    {
        backgroundImage: '#3dc1d3',
        images: [
            { label: 'work.formationacademy.image.info.1', image: faDashboardImage1 },
            { label: 'work.formationacademy.image.info.2', image: faDashboardImage2 },
            { label: 'work.formationacademy.image.info.3', image: faDashboardImage3 },
            { label: 'work.formationacademy.image.info.4', image: faDashboardImage4 },
            { label: 'work.formationacademy.image.info.5', image: faDashboardImage5 },
            { label: 'work.formationacademy.image.info.5', image: faDashboardImage6 },
            { label: 'work.formationacademy.image.info.6', image: faDashboardImage7 },
            { label: 'work.formationacademy.image.info.7', image: faDashboardImage8 },
            { label: 'work.formationacademy.image.info.8', image: faDashboardImage9 },
            { label: 'work.formationacademy.image.info.9', image: faDashboardImage13 },
            { label: 'work.formationacademy.image.info.10', image: faDashboardImage10 },
            { label: 'work.formationacademy.image.info.10', image: faDashboardImage11 },
            { label: 'work.formationacademy.image.info.10', image: faDashboardImage12 },
        ],
        title: 'FormationAcademy',
        year: '2020',
        description: 'work.formationacademy.description',
        missions: [
            WORK_MISSION_TYPE.front,
            WORK_MISSION_TYPE.back,
            WORK_MISSION_TYPE.uxui,
            WORK_MISSION_TYPE.projectManagement
        ],
        challenges: [
            'work.formationacademy.challenge.1',
            'work.formationacademy.challenge.2',
            'work.formationacademy.challenge.3',
            'work.formationacademy.challenge.4',
        ],
        techs: [PROGRAMMING_SKILLS[0], PROGRAMMING_SKILLS[1], PROGRAMMING_SKILLS[3], PROGRAMMING_SKILLS[4], PROGRAMMING_SKILLS[5], PROGRAMMING_SKILLS[7]],
        link: 'https://formation.academy/'
    },
    {
        backgroundImage: '#546de5',
        images: [
            { label: 'work.combee.image.info.2', image: combeeProImage1 },
            { label: 'work.combee.image.info.1', youtube: 'jZfm-JwJR6U' },
            { label: 'work.combee.image.info.3', image: combeeProImage2 },
            { label: 'work.combee.image.info.3', image: combeeProImage3 },
            { label: 'work.combee.image.info.4', image: combeeProImage4 },
            { label: 'work.combee.image.info.4', image: combeeProImage5 },
            { label: 'work.combee.image.info.4', image: combeeProImage6 },
            { label: 'work.combee.image.info.4', image: combeeProImage7 },
            { label: 'work.combee.image.info.5', image: combeeProImage8 },
            { label: 'work.combee.image.info.6', image: combeeProImage9 },
            { label: 'work.combee.image.info.7', image: combeeImage1, type: 'contain' },
            { label: 'work.combee.image.info.8', image: combeeImage2, type: 'contain' },
            { label: 'work.combee.image.info.9', image: combeeImage3, type: 'contain' },
            { label: 'work.combee.image.info.10', image: combeeImage4, type: 'contain' },
            { label: 'work.combee.image.info.11', image: combeeImage5, type: 'contain' },
            { label: 'work.combee.image.info.12', image: combeeImage6, type: 'contain' },
            { label: 'work.combee.image.info.13', image: combeeImage7, type: 'contain' },
            { label: 'work.combee.image.info.14', image: combeeImage8, type: 'contain' },
            { label: 'work.combee.image.info.15', image: combeeImage9, type: 'contain' },
            { label: 'work.combee.image.info.16', image: combeeImage10, type: 'contain' },
            { label: 'work.combee.image.info.17', image: combeeImage11, type: 'contain' },
            { label: 'work.combee.image.info.18', image: combeeImage12, type: 'contain' },
            { label: 'work.combee.image.info.19', image: combeeImage13, type: 'contain' },
            { label: 'work.combee.image.info.20', image: combeeImage14, type: 'contain' },
            { label: 'work.combee.image.info.21', image: combeeImage15, type: 'contain' },
            { label: 'work.combee.image.info.22', image: combeeImage16, type: 'contain' },
            { label: 'work.combee.image.info.23', image: combeeImage17, type: 'contain' },
            { label: 'work.combee.image.info.24', image: combeeImage18, type: 'contain' },
            { label: 'work.combee.image.info.24', image: combeeImage19, type: 'contain' },
            { label: 'work.combee.image.info.25', image: combeeImage20, type: 'contain' },
            { label: 'work.combee.image.info.26', image: combeeImage21, type: 'contain' },
            { label: 'work.combee.image.info.27', image: combeeImage22, type: 'contain' },
            { label: 'work.combee.image.info.28', image: combeeImage23, type: 'contain' },
        ],
        title: 'Combee',
        year: '2020',
        description: 'work.combee.description',
        missions: [
            WORK_MISSION_TYPE.front,
            WORK_MISSION_TYPE.back,
            WORK_MISSION_TYPE.uxui,
            WORK_MISSION_TYPE.business,
            WORK_MISSION_TYPE.projectManagement
        ],
        challenges: [
            'work.combee.challenge.1',
            'work.combee.challenge.2',
            'work.combee.challenge.3',
            'work.combee.challenge.4',
        ],
        techs: [PROGRAMMING_SKILLS[0], PROGRAMMING_SKILLS[1], PROGRAMMING_SKILLS[3], PROGRAMMING_SKILLS[4], PROGRAMMING_SKILLS[5], PROGRAMMING_SKILLS[7], {
            name: 'Stripe',
            level: 'high',
            logo: stripe
        }],
    },
];

export const CONTACT = [
    {
        id: 'phoneBE',
        icon: phoneBE,
        link: 'tel:+32475798974'
    },
    {
        id: 'phoneLU',
        icon: phoneLU,
        link: 'tel:+352691550231'
    },
    {
        id: 'mail',
        icon: email,
        link: 'mailto:contact@reunit.eu'
    }
];

export const METHOD = [
    {
        title: 'Prototypage (UX/UI)',
        description: 'method.prototype.description',
        tasks: [
            'method.prototype.objective.1',
            'method.prototype.objective.2',
            'method.prototype.objective.3'
        ]
    },
    {
        title: 'Développement',
        description: 'method.development.description',
        tasks: [
            'method.development.objective.1',
            'method.development.objective.2',
            'method.development.objective.3'
        ]
    },
    {
        title: 'Déployement & Support',
        description: 'method.support.description',
        tasks: [
            'method.support.objective.1',
            'method.support.objective.2',
            'method.support.objective.3'
        ]
    }
]

export const CONVERSATION_SENDER = {
    ROBOT: 0,
    USER: 1
};

export const CONVERSATION = [
    // INTRO
    {
        message: 'chat.intro.1',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 0,
        thenWait: false,
    },
    {
        message: 'chat.intro.2',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false
    },
    {
        message: 'chat.intro.3',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false
    },
    {
        message: 'chat.intro.4',
        by: CONVERSATION_SENDER.USER,
        delay: 1000,
        thenWait: true,
        choices: [
            {
                lbl: 'prendre contact avec vous 💬',
                goTo: 4
            },
            // {
            //     lbl: 'connaître tes tarifs 💶',
            //     goTo: 10
            // },
            // {
            //     lbl: 'connaître tes disponibilités 📆',
            //     goTo: 15
            // },
            {
                lbl: 'Fixer un rendez-vous 📍',
                goTo: 19
            }
        ]
    },

    // CONTACT
    {
        message: 'chat.contact.1',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 0,
        thenWait: false
    },
    {
        message: '✉️ contact@reunit.eu',
        link: 'mailto:contact@reunit.eu',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        message: '📱 +32 475 79 89 74 🇧🇪',
        link: 'tel:+32475798974',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false
    },
    {
        message: '📱 +352 691 550 231 🇱🇺',
        link: 'tel:+352691550231',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false
    },
    {
        message: 'chat.contact.2',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 1000,
        thenWait: true,
        goTo: 22
    },

    // RATE
    {
        message: 'chat.rate.1',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 0,
        thenWait: false,
    },
    {
        message: 'chat.rate.2',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 2000,
        thenWait: false,
    },
    {
        message: 'chat.rate.3',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        message: 'chat.rate.4',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        by: CONVERSATION_SENDER.USER,
        delay: 500,
        thenWait: true,
        choices: [
            {
                lbl: 'chat.choice.1',
                goTo: 4
            },
            {
                lbl: 'chat.choice.2',
                goTo: 15
            },
            {
                lbl: 'chat.choice.3',
                goTo: 24
            }
        ]
    },

    // AVAILABILITY
    {
        message: 'chat.availability.1',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 0,
        thenWait: false,
    },
    {
        message: 'chat.availability.2',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 2000,
        thenWait: false,
    },
    {
        message: 'chat.availability.3',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false
    },
    {
        message: 'chat.availability.4',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        by: CONVERSATION_SENDER.USER,
        delay: 500,
        thenWait: true,
        choices: [
            {
                lbl: 'chat.choice.1',
                goTo: 4
            },
            {
                lbl: 'chat.choice.4',
                goTo: 10
            },
            {
                lbl: 'chat.choice.3',
                goTo: 24
            }
        ]
    },

    // MAKE APPOINTMENT
    {
        message: 'chat.appointment.1',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 0,
        thenWait: false,
    },
    {
        message: 'chat.appointment.2',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false
    },
    {
        message: 'calendly.com/janssens/meet',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: true,
        link: 'https://calendly.com/janssens/meet',
        goTo: 23
    },

    // LOOP
    {
        message: 'chat.loop.1',
        by: CONVERSATION_SENDER.USER,
        delay: 500,
        thenWait: true,
        choices: [
            {
                lbl: 'Oui',
                goTo: 2
            },
            {
                lbl: 'Non',
                goTo: 24
            }
        ]
    },
    {
        message: 'chat.loop.2',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        by: CONVERSATION_SENDER.USER,
        delay: 0,
        thenWait: true,
        choices: [
            {
                lbl: 'chat.choice.5',
                goTo: 2
            }
        ]
    },
];