import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { Element } from 'react-scroll';
import Typist from 'react-typist';
import { CONTACT } from '../resources/constants/content';
import { MENU } from '../resources/constants/menu';
import Chat from './contact/Chat';
import ItemContactPanel from './contact/ItemContactPanel';

const ContactPanel = ({ blurValue, t, contactPanelRef, contactOverlay1Ref }) => {
    const [showChat, setShowChat] = useState(false);

    useEffect(() => {
        if (!showChat && blurValue >= 1)
            setShowChat(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blurValue]);

    return (
        <section className='page no-scroll-bar' ref={contactPanelRef}>
            <Element name={MENU[5].id}>
                <div className='content'>
                    <div className='title container flex align blue'>
                        <h1>
                            <div className='line'>
                                <div className='white-line-effect blue'>
                                    <div className='overlay transition' ref={contactOverlay1Ref}></div>
                                </div>
                                <span className='text'>{t("Time to work together")} !</span>
                            </div>
                        </h1>
                    </div>

                    <div className='container contact'>
                        <div className='face'>
                            <div className='img'>
                                <div className='chat-bubble left rounded-m shadow transition'>
                                    {showChat && <Typist cursor={{ show: false }}>
                                        {t("Hey, contact me")} !
                                        <span className='emoji'>✋🏻</span>
                                    </Typist>}
                                </div>
                            </div>
                        </div>

                        <div className='social'>
                            {CONTACT.map((contact, index) => <ItemContactPanel key={contact.id} contact={contact} showItems={blurValue > 0} position={index}  />)}
                        </div>

                        {showChat && <Chat show={blurValue > 0} />}
                    </div>
                </div>
            </Element>
        </section>
    );
}

export default withNamespaces()(ContactPanel);