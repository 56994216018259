import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Element, Link } from 'react-scroll';
import { MENU } from '../resources/constants/menu';

const CloudPanel = ({ blurValue, t, cloudPanelRef, cloudOverlay1Ref, cloudOverlay2Ref, cloudHighlight1Ref, cloudHighlight2Ref, cloudHighlight3Ref }) => {
    return (
        <section className='page no-scroll-bar' ref={cloudPanelRef}>
            <Element name={MENU[4].id}>
                <div className='content'>
                    <div className='title container flex align'>
                        <h1>
                            <div className='line'>
                                <div className='white-line-effect'>
                                    <div className='overlay transition' ref={cloudOverlay1Ref}></div>
                                </div>
                                <span className='highlighter selected'><span className='text'>{t("Training")}</span><div className='highlight transition' ref={cloudHighlight1Ref}></div></span> &amp; <span className='highlighter selected'><span className='text'>{t("Cloud")}.</span><div className='highlight transition' ref={cloudHighlight2Ref}></div></span><br />
                            </div>
                        </h1>
                    </div>

                    <div className='container methods'>
                        <div className={`method shadow rounded-m opacity-fade-effect ${blurValue > 0 ? 'visible' : ''}`} style={{ transitionDelay: `${1 * 0.5}s` }}>
                            <h3>{t('IT Training Courses')}</h3>
                            <p>{t('cloud.training.description')}</p>

                            <div className='center'>
                                <Link to='https://formation.academy' target='_blank'>
                                    <button className='default primary rounded-s transition'>
                                        <span className='main-text transition'>{t("cloud.training.cto")}</span>
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div className={`method shadow rounded-m opacity-fade-effect ${blurValue > 0 ? 'visible' : ''}`} style={{ transitionDelay: `${2 * 0.5}s` }}>
                            <h3>{t('Virtualization')}</h3>
                            <p>{t('cloud.virtualization.description')}</p>
                        </div>
                    </div>
                </div>
            </Element>
        </section>
    );
}

export default withNamespaces()(CloudPanel);