export const MENU = [
    {
        id: 'home',
        label: 'Home',
        position: 0
    },
    {
        id: 'works',
        label: 'Works',
        position: 1
    },
    {
        id: 'skills',
        label: 'Skills',
        position: 2
    },
    {
        id: 'method',
        label: 'How I work',
        position: 3
    },
    {
        id: 'other',
        label: 'Training & Cloud',
        position: 4
    },
    {
        id: 'rate',
        label: 'Daily rate & availabilities',
        position: 5,
        hidden: true,
    }
];